import React, { useEffect, useState } from "react";
import { FaqAPI } from "../API/FaqAPI";
import parse from "html-react-parser";
import { FaqLoader } from "../Loaders/FaqLoader";

const Faq = () => {
  const [allFaqs, setAllFaqs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    FaqAPI.getAll()
      .then((res) => {
        setAllFaqs(res.data.data.faqs.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="mb-5 mt-5">
        <div className="container faq-img ">
          <img src="/faqImg/faq1Img.png" alt="img1" />
        </div>
      </div>

      {loading ? (
        <FaqLoader />
      ) : (
        <div>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {allFaqs?.map((faq) => (
              <div className="accordion-item mb-4">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapseOne-${faq.id}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapseOne-${faq.id}`}
                  >
                    <span className="accordion">{faq?.questions}</span>
                  </button>
                </h2>
                <div
                  id={`flush-collapseOne-${faq.id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-collapseOne-${faq.id}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {parse(faq?.answers || "")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Faq;
