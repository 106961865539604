import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { AboutUsAPI } from "../API/AboutUsApi";
import parse from "html-react-parser";

const About = () => {
  const [about, setAbout] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AboutUsAPI.get()
      .then((res) => {
        setAbout(res.data.data.about);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="text-center">
        <h1 className="text text-center">
          Our <span style={{ color: "#CD0000" }}> Advisors</span>
        </h1>
        <img
          style={{ width: "45%", height: "100%" }}
          src="line.png"
          alt="line"
        />
      </div>
      <div className=" mt-5">
        <div className="row  mb-5">
          <div className="col-sm-12 col-md-5 col-lg-5 about-img2">
            <img src="./aboutImg/luna.jpg" alt="luna shrestha thakur" />
          </div>
          <div className=" mt-4 col-sm-12 col-md-7 col-lg-7">
            <div className=" text-center">
              <h1 className="text text-center">
                Luna Shrestha Thakur
              </h1>
              <img
                style={{ width: "60%", height: "100%" }}
                src="line.png"
                alt="line"
              />
              <div className="text-1 mt-5">
                <p>
                Luna Shrestha Thakur is founding director of Change Fusion Nepal which is a pioneer organization on establishing social entrepreneurship in Nepal. She led the Surya Nepal Asha Social Entrepreneurship Award for three years which awarded 5 different social enterprises every year from 2011 to 2013. SInce 2012, she has been supporting Seeing Hands Nepal as an advisor, friend, mentor on doing different works like establishing connections, establishing new clinics, establishing systems in the organization etc.
                </p>
                <p>
                She also worked as Country Director for Spring Accelerator which helped 11 businesses in Nepal that impacted adolescent girls.
                </p>
              </div>
            </div>
          </div>
        </div>


        {/* <div className="row  mb-5">
          <div className=" mt-4 col-sm-12 col-md-7 col-lg-7">
            <div className=" text-center">
              <h1 className="text text-center">
              Sonny Rosenthal
              </h1>
              <img
                style={{ width: "60%", height: "100%" }}
                src="line.png"
                alt="line"
              />
              <div className="text-1 mt-5">
                <p>
                Sonny Rosenthal is an assistant professor of strategic communication at Nanyang Technological University, Singapore. His teaching and research focus on the promotion of social and environmental causes. He serves on the editorial boards of the academic journals, Science Communication andEnvironmental Communication, and is on the board of advisors of EB Impact, a Singapore-based non-profit focusing on sustainable development in the Asia Pacific region.
                </p>
              </div>
            </div>
          </div>
            
          <div className="col-sm-12 col-md-5 col-lg-5 about-img2">
            <img src="./aboutImg/sonny.jpg" alt="sonny rosenthal" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default About;
