import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContactAPI } from "../API/ContactAPI";

const Footer = () => {
  const [contactInfo, setContactInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    ContactAPI.get()
      .then((res) => {
        setContactInfo(res.data.data.contactus);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="foot-sec-dv">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 foot-list ">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/booking">Booking</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 copyright-list">
              <ul>
                <li>
                  <p>Copyright 2023 © The Everest Barber. All rights reserved.</p>
                </li>
                <li>
                  <p>
                    Developed by:{" "}
                    <a target="_blank" href="#">
                      Creatu Developers
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
