import React from "react";
import Banner from "../Components/Banner";
import Feature from "../Components/Features";
import TypMass from "../Components/TypMass";
import MessFchair from "../Components/MessFchair";
import Branches from "../Components/Branches";

function Homepage() {
  return (
    <>
      <Banner />
      <MessFchair />
      <TypMass />
      <Branches />
      <Feature />
    </>
  );
}

export default Homepage;
