import React, { useEffect, useState } from "react";
import { GalleryAPI } from "../../API/GalleryAPI";
import GridLoader from "../../Loaders/GridLoader";

const VideoGallery = () => {
  const [allVideos, setAllVideos] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    GalleryAPI.getAllVideo()
      .then((res) => {
        setAllVideos(res.data.data.videos.data);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="text-center">
        <h1 className="text text-center">
          Video <span style={{ color: "#CD0000" }}> Gallery</span>
        </h1>
        <img style={{ width: "45%" }} src="/line.png" alt="line" />
      </div>

      {loading ? (
        <GridLoader item={3} row={2} />
      ) : (
        <div className="g-3 row mt-5 mb-5">
          {allVideos?.map((video) => (
            <div className="col-lg-4 mb-5 col-md-6 col-sm-12" key={video?.id}>
              <a href={`${video?.video_link}`} target="_blank">
                <div className="card" style={{ width: "100%" }}>
                  <img
                    src="./Gallery/VideoGallery1.png"
                    className="card-img-top"
                    alt="..."
                  />
                </div>
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoGallery;
