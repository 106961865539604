import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { CarouselAPI } from "../API/CarouselApi";
import { BranchAPI } from "../API/BranchAPI";
import { ServiceAPI } from "../API/ServiceAPI";
import { BookingAPI } from "../API/BookingAPI";
import { validateRequired } from "../helpers/validators";
import { fireErrorModal, fireSuccessModal } from "../providers/sweetalert";
import Skeleton from "react-loading-skeleton";

const initialState = {
  branch_id: "",
  service_id: "",
  time: "",
  date: "",
  name: "",
  email: "",
  phone_no: "",
  message: "",
};

const Banner = () => {
  const [booking, setBooking] = useState(false);

  const [allBranches, setAllBranches] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [carousel, setCarousel] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(true);

  const [bookingData, setBookingData] = useState(initialState);

  const handleBookingData = (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });
  };

  const [selectedServices, setSelectedServices] = useState([]);

  const handleCheckboxChange = (e) => {
    const serviceName = e.target.value;
    let updatedServices;

    if (e.target.checked) {
      updatedServices = [...selectedServices, serviceName];
    } else {
      updatedServices = selectedServices.filter((name) => name !== serviceName);
    }

    setSelectedServices(updatedServices);

    // Update the bookingData state with the concatenated string of selected service names
    setBookingData({ ...bookingData, service_id: updatedServices.join(", ") });
  };

  useEffect(() => {
    BranchAPI.getAll()
      .then((res) => {
        setAllBranches(res.data.data.branches.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
    ServiceAPI.getAll()
      .then((res) => {
        setAllServices(res.data.data.services.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
    CarouselAPI.carousel()
      .then((res) => {
        setImageURL(res.data.data.image_base_url);

        setCarousel(res.data.data.carousels.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);
  console.log(imageURL);

  const submitBookingData = (e) => {
    e.preventDefault();

    if (
      !validateRequired(bookingData, [
        { key: "branch_id", message: "Please select clinic" },
        { key: "service_id", message: "Please select a service" },
        { key: "time", message: "Please select duration" },
        { key: "date", message: "Please select a date" },
        { key: "name", message: "Please enter your name" },
        { key: "email", message: "Please enter your email" },
        { key: "phone_no", message: "Please enter your contact number" },
      ])
    ) {
      return;
    }

    setBooking(true);
    BookingAPI.book(bookingData)
      .then((res) => {
        console.log("Booking Successfull");
        fireSuccessModal("Thank you for the booking. Your booking has been received. We will call you for the confirmation. !", "Thank You");
        setBookingData(initialState);
        setBooking(false);
      })
      .catch((e) => {
        console.log(e.message);
        fireErrorModal("Failed to book due to some error", "Error");

        setBooking(false);
      });
  };

  return (
    <>
      {loading ? (
        <div className="item">
          <Skeleton width={"100%"} height={"100%"} />
        </div>
      ) : (
        <div>
          {carousel?.length > 0 && (
            <OwlCarousel className="owl-theme" items={1} loop={true} nav={true} dots={true}>
              {imageURL &&
                carousel.map((item) => (
                  <div className="item" key={item.id}>
                    <img src={`${imageURL}/${item?.image}`} alt={item.image_alt} />
                  </div>
                ))}
            </OwlCarousel>
          )}
        </div>
      )}

      <div className="bnr-fom-sec">
        <h5>Online Booking Form</h5>
        <form className="row g-2">
          <div className="col-lg-12 online-bok-fom">
            <input type="text" class="form-control" placeholder="Name" id="exampleInputEmail1" aria-describedby="emailHelp" name="name" value={bookingData?.name} onChange={handleBookingData} />
          </div>

          <div className="col-lg-12 online-bok-fom">
            <input type="email" class="form-control" placeholder="Email" id="exampleInputEmail1" aria-describedby="emailHelp" name="email" value={bookingData?.email} onChange={handleBookingData} />
          </div>

          <div className="col-lg-12 online-bok-fom">
            <input type="number" class="form-control" placeholder="Phone Number" id="exampleInputEmail1" aria-describedby="emailHelp" name="phone_no" value={bookingData?.phone_no} onChange={handleBookingData} />
          </div>

          <div className="col-lg-12 online-bok-fom">
            <small>Please select date</small>
          </div>

          <div className="col-lg-6 online-bok-fom">
            <input type="date" class="form-control" placeholder="Select Date" id="exampleInputEmail1" aria-describedby="emailHelp" name="date" value={bookingData?.date} onChange={handleBookingData} />
          </div>

          <div className="col-lg-6 online-bok-fom">
            <select class="form-select" aria-label="Default select example" name="time" value={bookingData?.time} onChange={handleBookingData}>
              <option value="">Select Time</option>
              <option>9 AM</option>
              <option>9:30 AM</option>
              <option>10 AM</option>
              <option>10:30 AM</option>
              <option>11 AM</option>
              <option>11:30 AM</option>
              <option>12 PM</option>
              <option>12:30 PM</option>
              <option>1 PM</option>
              <option>1:30 PM</option>
              <option>2 PM</option>
              <option>2:30 PM</option>
              <option>3 PM</option>
              <option>3:30 PM</option>
              <option>4 PM</option>
              <option>4:30 PM</option>
              <option>5 PM</option>
              <option>5:30 PM</option>
              <option>6 PM</option>
              <option>6:30 PM</option>
              <option>7 PM</option>
            </select>
          </div>

          <div className="col-lg-12 online-bok-fom">
            <div>
              <label>Select Service Type</label>
              <div className="checkbox-container">
                {allServices.map((service) => (
                  <div key={service.id} className="form-check">
                    <input className="form-check-input" type="checkbox" id={`service_${service.id}`} name="service_id" value={service.name} checked={selectedServices.includes(service.name)} onChange={handleCheckboxChange} />
                    <label className="form-check-label" htmlFor={`service_${service.id}`}>
                      {service.name} - {service.price}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-12 online-bok-fom">
            <select class="form-select" aria-label="Default select example" name="branch_id" value={bookingData?.branch_id} onChange={handleBookingData}>
              <option value="">Select Location</option>
              {allBranches.map((branch) => (
                <option value={branch?.id} key={branch?.id}>
                  {branch?.branch_name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-lg-12 online-bok-fom">
            <textarea className="form-control" rows={3} placeholder="Remarks" id="exampleInputEmail1" aria-describedby="emailHelp" name="message" value={bookingData?.message} onChange={handleBookingData}></textarea>
          </div>
          <div className="text-center">
            <button type="button" className="btn btn-primary fom-btn" onClick={submitBookingData} disabled={booking}>
              {booking ? "Booking" : "Book"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Banner;
