import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { BlogAPI } from "../../API/BlogAPI";
import parse from "html-react-parser";
import GridLoader from "../../Loaders/GridLoader";
import { DetailLoader } from "../../Loaders/DetailPageLoader";

const BlogDescription = () => {
  const [recommendedBlogs, setRecommendedBlogs] = useState([]);

  const [singleBlog, setSingleBlog] = useState({});
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  const params = useParams();

  useEffect(() => {
    setLoading(true);
    BlogAPI.getSingle(params?.id)
      .then((res) => {
        setSingleBlog(res.data.data.blog);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });

    BlogAPI.getAll()
      .then((res) => {
        let filteredBlogs = res.data.data.blogs.data.filter(
          (item) => item.id != params?.id
        );

        setRecommendedBlogs(filteredBlogs);
        // setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, [params]);

  return (
    <div className="container mt-4 mb-5">
      {loading ? (
        <DetailLoader />
      ) : (
        <>
          <div className="about-img">
            <img
              src={imageBaseURL + "/" + singleBlog?.image}
              alt={singleBlog?.image_alt}
            />
          </div>
          <div className="mt-4 blog-Description">
            <h1>{singleBlog?.title}</h1>
          </div>
          <div className="mt-4 blog-Description-text">
            {parse(singleBlog?.description || "")}
            {/* <div className="text-end">
          <button style={{ border: "0", backgroundColor: "white" }}>
            <img src="/blogImg/fbshare.png" alt="" />
          </button>
        </div> */}
          </div>
        </>
      )}

      <div className="mt-5">
        <div className="text-center">
          <h1 className="text text-center">
            <span style={{ color: "#CD0000" }}> Similar Blogs</span>
          </h1>
          <img style={{ width: "45%" }} src="/line.png" alt="line" />
        </div>

        {loading ? (
          <GridLoader />
        ) : (
          <div className="g-3 row mt-4">
            {recommendedBlogs.map((blog) => (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card" style={{ width: "100%" }}>
                  <img
                    src={imageBaseURL + "/" + blog?.image}
                    className="card-img-top"
                    alt={blog?.image_alt}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{blog?.title}</h5>
                    <p className="card-text">{blog?.summary}</p>
                  </div>
                  <div className="card-body text-end">
                    <Link
                      to={`/blog/${blog?.id}`}
                      className="card-link  blog-link"
                      style={{ textDecoration: "none", color: "#CD0000" }}
                    >
                      Read More <span className="fa fa-arrow-right"></span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogDescription;
