import React from "react";

const MessFchair = () => {
  return (
    <>
      <div className="container all-section">
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="text-center section">
              <h3>Learn about The Everest Barber</h3>
              <img classname="divider-img" src="./divider.png" alt="divider" />
            </div>
            <div className="txt-sec">
              <p>The Everest Barbers is Gorey Premier barber shopand men's grooming destination. Centrally located on Gorey main street beside suppervalue. Our team of barbers and stylist are ready to assist you to achieve your personal look. We take pride in using old techniques combined with modern styling to achieve a unique experience.</p>
              <p>Our team of barber are ready for the task wheather you need a haircut, straight razor shave, or someone to clear up your beard. The professionally trained staff always keeps a calm and friendly environment so your visit is gurantee to have a smile across your face for the rest of the day call us today to make your appointment.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4">
            <div className="chair-man-img">
              <img src="./wrapper-img.jpg" alt="Chair-Man-Imgae"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessFchair;
