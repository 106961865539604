import React, { useState, useEffect } from "react";
import { BookingAPI } from "../API/BookingAPI";
import { BranchAPI } from "../API/BranchAPI";
import { ServiceAPI } from "../API/ServiceAPI";
import { validateRequired } from "../helpers/validators";
import { lengthRequired } from "../helpers/validators";
import { fireErrorModal, fireSuccessModal } from "../providers/sweetalert";

const initialState = {
  branch_id: "",
  service_id: "",
  time: "",
  date: "",
  name: "",
  email: "",
  phone_no: "",
  message: "",
};

const Booking = () => {
  const [booking, setBooking] = useState(false);

  const [allBranches, setAllBranches] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const [bookingData, setBookingData] = useState(initialState);

  const handleBookingData = (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });
  };

  const [selectedServices, setSelectedServices] = useState([]);

  const handleCheckboxChange = (e) => {
    const serviceName = e.target.value;
    let updatedServices;

    if (e.target.checked) {
      updatedServices = [...selectedServices, serviceName];
    } else {
      updatedServices = selectedServices.filter((name) => name !== serviceName);
    }

    setSelectedServices(updatedServices);

    // Update the bookingData state with the concatenated string of selected service names
    setBookingData({ ...bookingData, service_id: updatedServices.join(", ") });
  };

  useEffect(() => {
    BranchAPI.getAll()
      .then((res) => {
        setAllBranches(res.data.data.branches.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
    ServiceAPI.getAll()
      .then((res) => {
        setAllServices(res.data.data.services.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  const submitBookingData = (e) => {
    e.preventDefault();

    if (
      !validateRequired(bookingData, [
        { key: "branch_id", message: "Please select branch" },
        { key: "service_id", message: "Please select a service" },
        { key: "time", message: "Please select time" },
        { key: "date", message: "Please select a date" },
        { key: "name", message: "Please enter your name" },
        { key: "email", message: "Please enter your email" },
        { key: "phone_no", message: "Please enter your contact number" },
      ])
    ) {
      return;
    }

    setBooking(true);
    BookingAPI.book(bookingData)
      .then((res) => {
        console.log("Booking SuccessFull");
        fireSuccessModal("Thank you for the booking. Your booking has been received. We will call you for the confirmation. !", "Thank You");
        setBookingData(initialState);
        setBooking(false);
      })
      .catch((e) => {
        console.log(e.message);
        fireErrorModal("Failed to book due to some error", "Error");

        setBooking(false);
      });
  };

  return (
    <div className="container mt-5">
      <header className="text-center">
        <h1 className="text text-center">
          Online <span style={{ color: "#6906a2" }}> Booking Form </span>
        </h1>
        <img style={{ width: "45%" }} src="line.png" alt="line" />
      </header>
      <body>
        <div className="row mt-5 mb-5 ">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="bookingImg ">
              <img src="./bookingImg/booking1img.png" alt="img2" />
            </div>
          </div>
          <form className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <fieldset>
              <div>
                <div className="bookPlace mb-3">
                  <input type="text" className="form-control" placeholder="Name" id="exampleInputEmail1" aria-describedby="emailHelp" name="name" value={bookingData?.name} onChange={handleBookingData} />
                </div>
                <div className="bookPlace mb-3">
                  <input type="email" className="form-control" placeholder="Email" id="exampleInputEmail1" aria-describedby="emailHelp" name="email" value={bookingData?.email} onChange={handleBookingData} />
                </div>
                <div className="bookPlace mb-3">
                  <input type="email" className="form-control" placeholder="Phone Number" id="exampleInputEmail1" aria-describedby="emailHelp" name="phone_no" value={bookingData?.phone_no} onChange={handleBookingData} />
                </div>
                <div className="bookPlace mb-3">
                  <small>Please select date</small>
                  <input type="date" className="form-control" placeholder="Select Date" id="exampleInputEmail1" aria-describedby="emailHelp" name="date" value={bookingData?.date} onChange={handleBookingData} />
                </div>

                <div className=" mb-3">
                  <select className="form-select" aria-label="Default select example" name="time" value={bookingData?.time} onChange={handleBookingData}>
                    <option value="">Select Time</option>
                    <option>9 AM</option>
                    <option>9:30 AM</option>
                    <option>10 AM</option>
                    <option>10:30 AM</option>
                    <option>11 AM</option>
                    <option>11:30 AM</option>
                    <option>12 PM</option>
                    <option>12:30 PM</option>
                    <option>1 PM</option>
                    <option>1:30 PM</option>
                    <option>2 PM</option>
                    <option>2:30 PM</option>
                    <option>3 PM</option>
                    <option>3:30 PM</option>
                    <option>4 PM</option>
                    <option>4:30 PM</option>
                    <option>5 PM</option>
                    <option>5:30 PM</option>
                    <option>6 PM</option>
                    <option>6:30 PM</option>
                    <option>7 PM</option>
                  </select>
                </div>
              </div>

              <div className=" mb-3">
                <div>
                  <label>Select Service Type</label>
                  <div className="checkbox-container">
                    {allServices.map((service) => (
                      <div key={service.id} className="form-check">
                        <input className="form-check-input" type="checkbox" id={`service_${service.id}`} name="service_id" value={service.id} checked={selectedServices.includes(service.id)} onChange={handleCheckboxChange} />
                        <label className="form-check-label" htmlFor={`service_${service.id}`}>
                          {service.name} - {service.price}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className=" mb-3">
                <select className="form-select" aria-label="Default select example" name="branch_id" value={bookingData?.branch} onChange={handleBookingData}>
                  <option value="">Select Loation</option>
                  {allBranches.map((branch) => (
                    <option value={branch?.id} key={branch?.id}>
                      {branch?.branch_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" mb-3">
                <textarea className="form-control" rows={3} placeholder="Remarks" id="exampleInputEmail1" aria-describedby="emailHelp" name="message" value={bookingData?.message} onChange={handleBookingData}></textarea>
              </div>

              <div className="d-grid">
                <button style={{ backgroundColor: "#1c1b9b" }} type="submit" className=" btn btn-primary" onClick={submitBookingData} disabled={booking}>
                  {booking ? "Booking" : "Book"}
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </body>
    </div>
  );
};

export default Booking;
