import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { AboutUsAPI } from "../API/AboutUsApi";
import parse from "html-react-parser";

const About = () => {
  const [about, setAbout] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AboutUsAPI.get()
      .then((res) => {
        setAbout(res.data.data.about);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="text-center">
        <h1 className="text text-center">
          About <span style={{ color: "#6906a2" }}> Everest Barber</span>
        </h1>
        <img style={{ width: "45%", height: "100%" }} src="line.png" alt="line" />
      </div>
      <div className="about-img mt-5">{loading ? <Skeleton width={"100%"} height={"100%"} /> : <img src={imageBaseURL + "/" + about?.image} alt="img1" />}</div>
      <div className="mt-5 text-1">
        <p>{parse(about?.description || "")}</p>
      </div>
    </div>
  );
};

export default About;
